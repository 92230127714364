<template>
  <div class="pageContol formCom">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" @click="pageBack()">人才库</a>
          <i>></i>
          <a href="javascript:;"
            >{{ pageInfo.talentId ? "编辑" : "新增" }} 人才</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form
              :model="pageInfo"
              ref="ruleForm"
              :rules="rules"
              label-width="150px"
              style="width: 500px"
            >
              <el-form-item label="近期照片：">
                <el-upload
                  :on-change="handleAvatarSuccess1"
                  :before-upload="beforeAvatarUpload2"
                  :http-request="$requestMine"
                  :show-file-list="false"
                  class="img-el-upload"
                  action
                  accept="image/png, image/gif, image/jpg, image/jpeg"
                >
                  <el-image
                    :src="pageInfo.photoUrl || require('@/assets/develop.png')"
                    fit="contain"
                    class="imgCenter"
                  ></el-image>
                </el-upload>
              </el-form-item>
              <el-form-item label="姓名：" prop="userName">
                <el-input
                  v-model="pageInfo.userName"
                  size="small"
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
              <el-form-item label="性别：" prop="sex">
                <el-select
                  v-model="pageInfo.sex"
                  size="small"
                  placeholder="请选择性别"
                >
                  <el-option label="男" value="1"></el-option>
                  <el-option label="女" value="2"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="手机号：" prop="mobile">
                <el-input
                  v-model="pageInfo.mobile"
                  size="small"
                  placeholder="请输入手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="出生日期：" prop="birthday">
                <el-date-picker
                  v-model="pageInfo.birthday"
                  type="date"
                  placeholder="选择出生日期"
                  value="yyyy-MM-dd"
                  size="small"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="学历：" prop="education">
                <el-select
                  clearable
                  size="small"
                  v-model="pageInfo.education"
                  placeholder="请选择学历"
                >
                  <el-option
                    v-for="item in educationData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="毕业院校：" prop="graduateInstitutions">
                <el-input
                  v-model="pageInfo.graduateInstitutions"
                  size="small"
                  placeholder="请输入毕业院校"
                ></el-input>
              </el-form-item>
              <el-form-item label="意向职位：" prop="positionName">
                <el-input
                  v-model="pageInfo.positionName"
                  size="small"
                  placeholder="请选择意向职位"
                  @focus="inputpositio"
                ></el-input>
              </el-form-item>
              <el-form-item label="参加工作时间：" prop="joinWork">
                <el-date-picker
                  v-model="pageInfo.joinWork"
                  type="date"
                  placeholder="选择参加工作时间"
                  value="yyyy/MM/dd"
                  size="small"
                  value-format="yyyy/MM/dd"
                ></el-date-picker>
              </el-form-item>
              <!-- <el-form-item label="现工作单位：" prop="workUnit">
                <el-input
                  v-model="pageInfo.workUnit"
                  size="small"
                  placeholder="请输入现工作单位"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="人才来源：" prop="talentSource">
                <el-select
                  clearable
                  size="small"
                  v-model="pageInfo.talentSource"
                  placeholder="请选择人才来源"
                >
                  <el-option
                    v-for="item in talentSourceData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="现居住地：" prop="currentAddress">
                <el-input
                  v-model="pageInfo.currentAddress"
                  size="small"
                  placeholder="请输入现居住地"
                ></el-input>
              </el-form-item> -->
              <el-form-item label="求职状态：" prop="jobSeekingState">
                <el-select
                  clearable
                  size="small"
                  v-model="pageInfo.jobSeekingState"
                  placeholder="请选择求职状态"
                >
                  <el-option
                    v-for="item in jobSeekingStateData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="所在城市：" prop="areaId">
                <el-cascader
                  v-model="pageInfo.areaId"
                  :options="areaIdData"
                  :props="propsarea"
                  clearable
                  filterable
                  size="small"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="到岗时间：" prop="jobSeekingState">
                <el-select
                  clearable
                  size="small"
                  v-model="pageInfo.arrivalTime"
                  placeholder="请选择到岗时间"
                >
                  <el-option
                    v-for="item in arrivalTimeData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="简历">
                <el-upload
                  class="upload-btndrag"
                  :action="actionUrl"
                  :on-error="handleError"
                  :on-success="handleSuccess2"
                  :on-change="uploadChange2"
                  :show-file-list="false"
                  :auto-upload="false"
                  drag
                  style="height: auto !important"
                >
                  <i class="el-icon-upload"></i>
                  <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击上传</em>
                  </div>
                </el-upload>
                <div
                  v-if="pageInfo.resumeList.length > 0"
                  style="margin-top: 10px; margin-left: 10px"
                >
                  当前选择文件：
                  <div>
                    <span
                      style="color: #409eff"
                      v-for="(item, index) in pageInfo.resumeList"
                      :key="index"
                    >
                      {{ item.fileName }}
                      <a
                        @click="lookExl(item)"
                        style="color: #409eff; margin: 0 15px"
                        >预览</a
                      >
                      <a
                        @click="reomveExlUpload2(index)"
                        style="color: red; margin: 0 15px"
                        >删除</a
                      >
                    </span>
                  </div>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button @click="pageBack" class="bgc-bv">取消</el-button>
                <el-button @click="seveData('ruleForm')" class="bgc-bv"
                  >保存</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- 预览简历 -->
    <el-dialog
      title="预览简历"
      :visible.sync="previewLoding"
      width="80%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div id="pdf-cert1" style="height: 600px"></div>
      </div>
    </el-dialog>
    <!-- 意向职位 -->
    <el-dialog
      title="最近职位"
      :visible.sync="previewLoding2"
      width="40%"
      top="5%"
      center
    >
      <div class="zjzw">
        <div>
          <div
            :class="
              previewLodingData_1_positionTypeId == item.positionTypeId
                ? 'ts_1'
                : ''
            "
            v-for="(item, index) in previewLodingData_1"
            :key="index"
            @click="selectpreview(1, item, item.positionTypeId)"
          >
            {{ item.positionTypeName }}
          </div>
        </div>
        <div>
          <div
            :class="
              previewLodingData_2_positionTypeId == item.positionTypeId
                ? 'ts_1'
                : ''
            "
            v-for="(item, index) in previewLodingData_2"
            :key="index"
            @click="selectpreview(2, item, item.positionTypeId)"
          >
            {{ item.positionTypeName }}
          </div>
        </div>
        <div>
          <div
            :class="
              previewLodingData_3_positionTypeId == item.positionTypeId
                ? 'ts_1'
                : ''
            "
            v-for="(item, index) in previewLodingData_3"
            :key="index"
            @click="selectpreview(3, item, item.positionTypeId)"
          >
            {{ item.positionTypeName }}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
export default {
  name: "hr_recruitModule_personnelLibrary_addPersonnel",
  data() {
    return {
      // 页面数据
      pageInfo: {
        talentId: "", // 人才信息id
        photoKey: "", // 近期照片 - key
        photoUrl: "", // 近期照片 - url
        userName: "", // 姓名
        sex: "", // 性别
        mobile: "", // 手机号
        birthday: "", // 出生日期
        educationExperienceId: "", //教育经历主键id
        education: "", //学历
        graduateInstitutions: "", // 毕业院校
        joinWork: "", // 参加工作时间
        // workUnit: "", // 现工作单位
        jobIntentionId: "", //最新意向职位主键id
        positionName: "",   // 意向职位 - 页面显示name
        positionId: "",     // 意向职位 - 就是三级类数据id
        talentSource: "", // 人才来源
        // currentAddress: "", // 现居住地
        jobSeekingState: "", // 求职状态
        areaId: "", // 所在城市
        arrivalTime:"", // 到岗时间
        resumeList: [], // 简历
      },
      //可用学历数组
      educationData: [],
      // 人才来源 - 下拉数据
      talentSourceData: [],
      // 求职状态 - 下拉数据
      jobSeekingStateData: [],
    //   所在城市 - 下拉数据
      areaIdData: [],
    //   所在城市 - 数据格式
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      // 到岗时间 - 下拉数据
      arrivalTimeData:[],
      // 页面数据 - 数据校验
      rules: {
        userName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        sex: [
          {
            required: true,
            message: "请选择性别",
            trigger: "change",
          },
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        birthday: [
          {
            required: true,
            message: "请选择时间",
            trigger: "change",
          },
        ],
      },
      // 预览简历 - 弹框
      previewLoding: false,
      // 意向职位 - 弹框
      previewLoding2: false,
      // 意向职位 - 一级类数据 - 也是所有数据
      previewLodingData_1: [],
      // 意向职位 - 二级类数据
      previewLodingData_2: [],
      // 意向职位 - 三级类数据
      previewLodingData_3: [],
      // 意向职位 - 一级类数据 - 当前id
      previewLodingData_1_positionTypeId: "",
      // 意向职位 - 二级类数据 - 当前id
      previewLodingData_2_positionTypeId: "",
      // 意向职位 - 三级类数据 - 当前id
      previewLodingData_3_positionTypeId: "",

      retrievalData:{},
      currentPage:''
    };
  },
  mounted() {},
  created() {
    this.pageInfo.talentId = this.$route.query.talentId || '';
    this.getareatree();
    this.getInvoice_type();
    this.getPreviewLoding2();
    if (this.$route.query.talentId) {
      this.getInfo(this.$route.query.talentId);
    }
    if(this.$route.query.retrievalData){
      this.retrievalData = this.$route.query.retrievalData
    }
    if(this.$route.query.currentPage){
      this.currentPage = this.$route.query.currentPage
    }
  },
  methods: {
    // 返回上一页
    pageBack(){
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/personnelList",
        query: {
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 获取 - 城市区划
    getareatree() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.areaIdData = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取 - 获取字典
    getInvoice_type() {
      // 人才来源
      let arr1 = this.$setDictionary("HR_TALENT_SOURCE", "list");
      for (const key in arr1) {
        this.talentSourceData.push({
          label: arr1[key],
          value: key,
        });
      }
      // 求职状态
      let arr2 = this.$setDictionary("HR_JOB_SEEKING_STATE", "list");
      for (const key in arr2) {
        this.jobSeekingStateData.push({
          label: arr2[key],
          value: key,
        });
      }
      // 到岗时间
      let arr3 = this.$setDictionary("HR_ARRIVAL_TIME", "list");
      for (const key in arr3) {
        this.arrivalTimeData.push({
          label: arr3[key],
          value: key,
        });
      }
      // 可选学历
      let arr4 = this.$setDictionary("HR_EDUCATION", "list");
      for (const key in arr4) {
        this.educationData.push({
          label: arr4[key],
          value: key,
        });
      }
    },
    // 上传头像
    handleAvatarSuccess1(res, list) {
      let formData = new FormData();
      let fileName = res.name; // 文件名
      let ind = fileName.lastIndexOf(".");
      let fileName_1 = fileName.slice(0, ind);
      if (res.size / 1024 / 1024 > 10) {
        this.$message.error("文件大小不能超过10MB");
        return;
      }
      if (fileName_1.length < 3) {
        this.$message.error("文件名称不能少于3个字符");
        return;
      }
      let extension = fileName.replace(/.+\./, ""); // 文件类型
      const isXLSX = extension == "jpg";
      const isXLSXe = extension == "jpeg";
      const isxlsx = extension == "png";
      if (!isXLSX && !isxlsx && !isXLSXe) {
        this.$message.error("请上传jpg/png/jpeg格式的图片");
        return;
      }
      formData.append("file", res.raw);
      formData.append("folder ", "TEMP");
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((res) => {
          this.pageInfo.photoKey = res.data.fileKey;
          this.pageInfo.photoUrl = res.data.fileURL;
          this.$set(this.pageInfo,'photoUrl', res.data.fileURL)
        })
        .catch(() => {
          setTimeout(() => {
            this.$message({
              type: "warning",
              message: "上传失败",
            });
          }, 300);
        });
    },
    // 上传简历
    uploadChange2(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "PDF";
      const isxlsx = extension === "pdf";
      if (!isXLSX && !isxlsx) {
        this.$message.error("请上传.pdf或.PDF文件");
        return;
      }
      if (size > 10) {
        this.$message.error("文件大小不能超过10M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);
      this.$Postformat("/sys/upload", formData)
        .then((res) => {
          this.pageInfo.resumeList.push({
            fileName: file.name,
            fileKey: res.data.fileKey,
            fileType: extension,
            fileUrl: res.data.fileURL,
          });
        })
        .catch((err) => {
          return;
        });
    },
    // 预览简历
    lookExl(item) {
      this.previewLoding = true;
      this.$nextTick(() => {
        pdf.embed(item.fileUrl, "#pdf-cert1");
      });
    },
    // 删除简历
    reomveExlUpload2(index) {
      this.pageInfo.resumeList.splice(index, 1);
      this.$forceUpdate();
    },
    //点击 - 最近职位
    inputpositio() {
      this.previewLoding2 = true;
    },
    //获取 - 最近职位：但只取当前第一组数据
    getPreviewLoding2() {
      this.$post("/hr/talent/hr-position-type/queryAll")
        .then((res) => {
          this.previewLodingData_1 = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 点击 - 职业分类
    selectpreview(type, item, positionTypeId) {
      if (type == 1) {
        this.previewLodingData_1_positionTypeId = positionTypeId;
        this.previewLodingData_2 = item.children;
        this.previewLodingData_3 = [];
      }
      if (type == 2) {
        this.previewLodingData_2_positionTypeId = positionTypeId;
        this.previewLodingData_3 = item.children;
      }
      if (type == 3) {
        this.previewLodingData_3_positionTypeId = positionTypeId;
        this.pageInfo.positionId = positionTypeId;
        this.pageInfo.positionName = item.positionTypeName;
        this.previewLoding2 = false;
      }
    },
    // 获取 - 详情
    getInfo(talentId) {
      this.$post("hr/talent/info/getById", {
        talentId,
      })
        .then((res) => {
          this.pageInfo = {
            ...this.pageInfo,
            ...res.data,
            birthday:res?.data?.birthday?res.data.birthday.replaceAll("/","-"):""
          };
        })
        .catch((err) => {
          return;
        });
    },
    // 取消
    // cancle() {
    //   this.$router.back();
    // },
    // 保存
    seveData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let parmar = {
            userName: this.pageInfo.userName,
            sex: this.pageInfo.sex,
            mobile: this.pageInfo.mobile,
            birthday: this.pageInfo.birthday,
            entryPoint: "OPERATION", // 数据填报入口-固定这么写死
          };
          if(this.pageInfo.talentId){
            parmar.talentId = this.pageInfo.talentId;
          }
          if (this.pageInfo.photoKey) {
            parmar.photoKey = this.pageInfo.photoKey;
          }
          if (this.pageInfo.educationExperienceId) {
            parmar.educationExperienceId = this.pageInfo.educationExperienceId;
          }
          if (this.pageInfo.education) {
            parmar.education = this.pageInfo.education;
          }
          if (this.pageInfo.graduateInstitutions) {
            parmar.graduateInstitutions = this.pageInfo.graduateInstitutions;
          }
          if (this.pageInfo.joinWork) {
            parmar.joinWork = this.pageInfo.joinWork;
          }
        //   if (this.pageInfo.workUnit) {
        //     parmar.workUnit = this.pageInfo.workUnit;
        //   }
          if (this.pageInfo.jobIntentionId) {
            parmar.jobIntentionId = this.pageInfo.jobIntentionId;
          }
          if (this.pageInfo.positionId) {
            parmar.positionId = this.pageInfo.positionId;
          }
          if (this.pageInfo.talentSource) {
            parmar.talentSource = this.pageInfo.talentSource;
          }
        //   if (this.pageInfo.currentAddress) {
        //     parmar.currentAddress = this.pageInfo.currentAddress;
        //   }
          if (this.pageInfo.jobSeekingState) {
            parmar.jobSeekingState = this.pageInfo.jobSeekingState;
          }
          if (this.pageInfo.areaId) {
            parmar.areaId = this.pageInfo.areaId;
          }
           if (this.pageInfo.arrivalTime) {
            parmar.arrivalTime = this.pageInfo.arrivalTime;
          }
          if (this.pageInfo.resumeList.length > 0) {
            parmar.resumeList = this.pageInfo.resumeList;
          }
          this.$post("/hr/talent/info/save", parmar)
            .then((res) => {
              console.log(res)
              if (res.status == "0") {
                this.$message({
                  message: res.message,
                  type: "success",
                });
                this.pageBack()
              }
            })
            .catch((err) => {
              return;
            });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.ts_1 {
  border-left: 4px solid #6383f9;
  padding-left: 4px !important;
  background-image: linear-gradient(to left, rgba(255, 0, 0, 0), #b7c6fd);
}
.el-form {
  .el-form-item {
    margin-bottom: 18px;
  }
}
.zjzw {
  height: 450px;
  display: flex;
  justify-content: space-between;
  > div {
    overflow-y: auto;
    overflow-x: hidden;
    width: 33.33%;
    margin-right: 4%;
    > div {
      line-height: 26px;
      padding-left: 10px;
      margin-bottom: 10px;
      cursor: pointer;
    }
  }
}
</style>